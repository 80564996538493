
















































































































































import { SfOverlay, SfHeader, SfButton, SfBadge } from '@storefront-ui/vue';
import { useHidePriceStore } from '@/components/TopBar/hidePriceStore';
import {
  computed,
  ref,
  defineComponent,
  useRouter,
  useRoute,
  useContext,
  onMounted,
  useFetch,
} from '@nuxtjs/composition-api';
import HeaderNavigation from '~/components/Header/Navigation/HeaderNavigation.vue';
import { useCategory } from '~/modules/catalog/category/composables/useCategory';
import { useUiHelpers, useUiState } from '~/composables';
import { useCart } from '~/modules/checkout/composables/useCart';
import { useWishlist } from '~/modules/wishlist/composables/useWishlist';
import { useUser } from '~/modules/customer/composables/useUser';
import { useWishlistStore } from '~/modules/wishlist/store/wishlistStore';
import type { CategoryTree, ProductInterface } from '~/modules/GraphQL/types';
import HeaderLogo from '~/components/HeaderLogo.vue';
import SvgImage from '~/components/General/SvgImage.vue';
import StoreSwitcher from '~/components/StoreSwitcher.vue';

export default defineComponent({
  components: {
    HeaderNavigation,
    SfHeader,
    SfOverlay,
    HeaderLogo,
    StoreSwitcher,
    SvgImage,
    SfButton,
    SfBadge,
    SearchBar: () => import('~/components/Header/SearchBar/SearchBar.vue'),
    SearchResults: () =>
      import(
        /* webpackPrefetch: true */ '~/components/Header/SearchBar/SearchResults.vue'
      ),
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const { app } = useContext();
    const { toggleLoginModal } = useUiState();
    const { setTermForUrl, getCatLink } = useUiHelpers();
    const { isAuthenticated } = useUser();
    const { loadTotalQty: loadCartTotalQty, cart } = useCart();
    const { loadItemsCount: loadWishlistItemsCount } = useWishlist();
    const { categories: categoryList, load: categoriesListLoad } =
      useCategory();
    const storeLanguage = route.value.path.substring(0,7)==="/b2b_en"? '/b2b_en':'/b2bFR'
    const isSearchOpen = ref(false);
    const loadingProduct = ref(null);
    const searchTerm = ref(null);
    const debouncedSearchTerm = ref(null);
    const productSearchResults = ref<ProductInterface[] | null>(null);
    const wishlistStore = useWishlistStore();
    const hidePriceStore = useHidePriceStore();
    const priceIsVisible = ref(hidePriceStore.hidePrice);
    hidePriceStore.$subscribe((mutation, state) => {
      priceIsVisible.value = state.hidePrice;
    });
    const wishlistItemsQty = computed(
      () => wishlistStore.wishlist?.items_count ?? 0
    );

    const CartRouter = () => {
      const url = document.location.pathname;
      return url;
    };
    const routeCart = app.localePath('/panier');
    const routeStore = app.localePath('/magasins');

    const wishlistHasProducts = computed(() => wishlistItemsQty.value > 0);
    const accountIcon = computed(() =>
      isAuthenticated.value ? 'profile_fill' : 'profile'
    );
    const categoryTree = ref<CategoryTree[]>([]);
    const handleAccountClick = async () => {
      if (isAuthenticated.value) {
        await router.push(app.localeRoute({ name: 'customer-my-profile' }));
      } else {
        toggleLoginModal();
      }
    };

    const handleCartClick = async () => {
      await router.push(app.localeRoute({ name: 'cart' }));
    };

    const goToWishlist = async () => {
      await router.push(app.localeRoute({ name: 'wishlist' }));
    };

    const closeSearch = () => {
      isSearchOpen.value = false;
      document.body.classList.remove('no-scroll');
    };

    useFetch(async () => {
      await categoriesListLoad({ pageSize: 20 });

      categoryTree.value = categoryList.value?.[0]?.children.filter(
        (category) => category.include_in_menu
      );
    });

    onMounted(async () => {
      if (app.$device.isDesktop) {
        await loadCartTotalQty();
        // eslint-disable-next-line promise/catch-or-return
        await loadWishlistItemsCount({});
      }
    });

    return {
      accountIcon,
      cartTotalItems: computed(() => cart.value?.total_quantity ?? 0),
      categoryTree,
      getCatLink,
      handleAccountClick,
      isAuthenticated,
      isSearchOpen,
      productSearchResults,
      setTermForUrl,
      handleCartClick,
      goToWishlist,
      wishlistHasProducts,
      wishlistItemsQty,
      searchTerm,
      debouncedSearchTerm,
      loadingProduct,
      CartRouter,
      routeCart,
      routeStore,
      closeSearch,
      storeLanguage,
      priceIsVisible,
    };
  },
});
