import { Middleware } from '@nuxt/types';
import { useCustomerStore } from '~/modules/customer/stores/customer';

const middleware: Middleware = async (context) => {
  const customerStore = useCustomerStore();
  let user;
  if (!context.app.$vsf.$magento.config.state.getCustomerToken() ) {
    context.redirect(context.app.localePath('/connexion'));
  } else {
    if(customerStore.user === null) {
      const { data } = await context.app.$vsf.$magento.api.customer({ customer: 'customer' });
      user = data;
    } else {
      user = { customer: customerStore.user };
    } 
    if (user?.customer?.is_approved !== 'approved') {
      context.redirect(context.app.localePath('/compte-en-attente'));
    }
  }
};
export default middleware;