import locale77526775 from '../../lang/fr.js'

export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
}
export const nuxtOptions = {
  isUniversalMode: false,
  trailingSlash: undefined,
}
export const options = {
  vueI18n: {"fallbackLocale":"b2bFR","numberFormats":{"b2bFR":{"currency":{"style":"currency","currency":"EUR","currencyDisplay":"symbol"}},"b2b_en":{"currency":{"style":"currency","currency":"EUR","currencyDisplay":"symbol"}}}},
  vueI18nLoader: false,
  locales: [{"code":"b2bFR","file":"fr.js","iso":"FR","defaultCurrency":"EUR"},{"code":"b2b_en","file":"en.js","iso":"EN","defaultCurrency":"EUR"}],
  defaultLocale: "b2bFR",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  sortRoutes: true,
  strategy: "prefix",
  lazy: true,
  langDir: "/home/users/b2b-prod/Vue-store-front-promulias-b2b/packages/theme/lang",
  rootRedirect: null,
  detectBrowserLanguage: false,
  differentDomains: false,
  seo: true,
  baseUrl: "https://pro.promulias.com/",
  vuex: {"moduleName":"i18n","syncLocale":false,"syncMessages":false,"syncRouteParams":true},
  parsePages: true,
  pages: {},
  skipSettingLocaleOnNavigate: false,
  beforeLanguageSwitch: () => null,
  onBeforeLanguageSwitch: () => {},
  onLanguageSwitched: () => null,
  country: "FR",
  autoChangeCookie: {"currency":true,"locale":true,"country":true},
  normalizedLocales: [{"code":"b2bFR","file":"fr.js","iso":"FR","defaultCurrency":"EUR"},{"code":"b2b_en","file":"en.js","iso":"EN","defaultCurrency":"EUR"}],
  localeCodes: ["b2bFR","b2b_en"],
}

export const localeMessages = {
  'fr.js': () => Promise.resolve(locale77526775),
  'en.js': () => import('../../lang/en.js' /* webpackChunkName: "lang-en.js" */),
}
