





















































import LazyHydrate from 'vue-lazy-hydration';
import { SfModal, SfButton } from '@storefront-ui/vue';
import {
  useRoute,
  defineComponent,
  onBeforeMount,
  ref,
  onMounted,
  watch,
  useContext,
} from '@nuxtjs/composition-api';
import useCart from '~/modules/checkout/composables/useCart';
import { useUser } from '~/modules/customer/composables/useUser';
import AppHeader from '~/components/AppHeader.vue';
import BottomNavigation from '~/components/BottomNavigation.vue';
import IconSprite from '~/components/General/IconSprite.vue';
import LoadWhenVisible from '~/components/utils/LoadWhenVisible.vue';
import TopBar from '~/components/TopBar/TopBar.vue';
import Cookies from 'cookie-universal';
import AppHeaderCheckout from '~/components/AppHeaderCheckout.vue';

export default defineComponent({
  name: 'DefaultLayout',

  components: {
    SfModal,
    SfButton,
    LoadWhenVisible,
    LazyHydrate,
    AppHeader,
    BottomNavigation,
    IconSprite,
    TopBar,
    AppHeaderCheckout,
    AppFooter: () =>
      import(/* webpackPrefetch: true */ '~/components/AppFooter.vue'),
    Notification: () =>
      import(/* webpackPrefetch: true */ '~/components/Notification.vue'),
  },

  setup() {
    function silentErrorHandler() {
      return true;
    }

    const { app } = useContext();
    const cookies = Cookies();
    const route = useRoute();
    const { isAuthenticated, load } = useUser();
    const userStatus = ref(null);
    const { load: loadCart } = useCart();
    const topbarDisabled = ref(false);
    const isCookiesAccepted = ref(false);
    isCookiesAccepted.value = cookies.get('vsf-accept-cookies');

    function checkPath() {
        topbarDisabled.value = !!(
          route.value.fullPath.includes('shipping') ||
          route.value.fullPath.includes('billing') ||
          route.value.fullPath.includes('payment')
        );
    }

    onBeforeMount(async () => {
      if (!route.value.fullPath.includes('reset-password')) {
        userStatus.value = (await load()).is_approved;
        await loadCart();
      }
    });

    const switchHeader = () => {
      topbarDisabled.value = !topbarDisabled.value;
    };

    const dateNow = new Date();
    function add30DaysToDate(date, days) {
      var Date30 = new Date(date);
      Date30.setDate(Date30.getDate() + days);
      return Date30;
    }

    const acceptCookie = () => {
      cookies.set('vsf-accept-cookies', true, {
        expires: add30DaysToDate(dateNow, 30),
      });
      isCookiesAccepted.value = true;
    };

    watch(route, () => {
      checkPath();
    });

    onMounted(async () => {
      await loadCart();
      checkPath();
      console.clear();
      console.log = () => {};
      console.debug = () => {};
      console.info = () => {};
      console.warn = () => {};
    });

    return {
      app,
      route,
      isAuthenticated,
      userStatus,
      isCookiesAccepted,
      acceptCookie,
      checkPath,
      topbarDisabled,
      switchHeader,
    };
  },
});
