import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _67bab3ee = () => interopDefault(import('../pages/About.vue' /* webpackChunkName: "" */))
const _543b868e = () => interopDefault(import('../pages/Home.vue' /* webpackChunkName: "" */))
const _719a044f = () => interopDefault(import('../pages/Cart.vue' /* webpackChunkName: "pages/Cart" */))
const _68c96d32 = () => interopDefault(import('../pages/Cgu.vue' /* webpackChunkName: "pages/Cgu" */))
const _68d784b3 = () => interopDefault(import('../pages/Cgv.vue' /* webpackChunkName: "pages/Cgv" */))
const _04333d84 = () => interopDefault(import('../modules/checkout/pages/Checkout.vue' /* webpackChunkName: "" */))
const _3917cc8a = () => interopDefault(import('../modules/checkout/pages/Checkout/Billing.vue' /* webpackChunkName: "" */))
const _5071c2d6 = () => interopDefault(import('../modules/checkout/pages/Checkout/Payment.vue' /* webpackChunkName: "" */))
const _299bfb2f = () => interopDefault(import('../modules/checkout/pages/Checkout/Shipping.vue' /* webpackChunkName: "" */))
const _1329c6d6 = () => interopDefault(import('../modules/checkout/pages/Checkout/ThankYou.vue' /* webpackChunkName: "" */))

const _2f2b8766 = () => interopDefault(import('../pages/HowToChooseWeddingDress.vue' /* webpackChunkName: "" */))
const _5f96be05 = () => interopDefault(import('../pages/PendingAccount.vue' /* webpackChunkName: "" */))
const _511a31ca = () => interopDefault(import('../pages/Login.vue' /* webpackChunkName: "" */))
const _7b1eda3f = () => interopDefault(import('../pages/ContactUs.vue' /* webpackChunkName: "" */))
const _4269f05a = () => interopDefault(import('../pages/Cookie.vue' /* webpackChunkName: "pages/Cookie" */))
const _50d04e63 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyAccount.vue' /* webpackChunkName: "" */))
const _090b3cd1 = () => interopDefault(import('../modules/customer/pages/MyAccount/AddressesDetails/AddressesDetails.vue' /* webpackChunkName: "" */))
const _304265f3 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyNewsletter.vue' /* webpackChunkName: "" */))
const _ef2449e6 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyProfile/MyProfile.vue' /* webpackChunkName: "" */))
const _6f4a1151 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyReviews.vue' /* webpackChunkName: "" */))
const _792d117f = () => interopDefault(import('../modules/customer/pages/MyAccount/MyWishlist.vue' /* webpackChunkName: "" */))
const _3d141411 = () => interopDefault(import('../modules/customer/pages/MyAccount/OrderHistory/OrderHistory.vue' /* webpackChunkName: "" */))
const _d5dc9b46 = () => interopDefault(import('../modules/customer/pages/MyAccount/AddressesDetails/AddressNew.vue' /* webpackChunkName: "" */))
const _1d29aa1d = () => interopDefault(import('../modules/customer/pages/MyAccount/AddressesDetails/AddressEdit.vue' /* webpackChunkName: "" */))
const _1cb8da42 = () => interopDefault(import('../modules/customer/pages/MyAccount/OrderHistory/SingleOrder/SingleOrder.vue' /* webpackChunkName: "" */))
const _9ff90eec = () => interopDefault(import('../pages/DeliveryInformations.vue' /* webpackChunkName: "pages/DeliveryInformations" */))
const _78ae7c08 = () => interopDefault(import('../pages/Events.vue' /* webpackChunkName: "" */))
const _05f2a592 = () => interopDefault(import('../pages/Faq.vue' /* webpackChunkName: "" */))
const _4a248cf6 = () => interopDefault(import('../pages/FindOrder.vue' /* webpackChunkName: "pages/FindOrder" */))
const _94cdca5c = () => interopDefault(import('../pages/Register.vue' /* webpackChunkName: "" */))
const _7f8f4adc = () => interopDefault(import('../pages/LegalNotice.vue' /* webpackChunkName: "pages/LegalNotice" */))
const _456d4bb7 = () => interopDefault(import('../pages/Lookbook.vue' /* webpackChunkName: "" */))
const _87e987b8 = () => interopDefault(import('../pages/StoreLocator.vue' /* webpackChunkName: "" */))
const _b71ec784 = () => interopDefault(import('../pages/Page.vue' /* webpackChunkName: "pages/Page" */))
const _a6ac908a = () => interopDefault(import('../pages/PrivacyPolicy.vue' /* webpackChunkName: "" */))
const _0c25adeb = () => interopDefault(import('../pages/ResetPassword.vue' /* webpackChunkName: "" */))
const _60aec9f2 = () => interopDefault(import('../pages/WeddingDress.vue' /* webpackChunkName: "" */))
const _1d3e3f48 = () => interopDefault(import('../pages/SizeGuide.vue' /* webpackChunkName: "" */))
const _41d0635e = () => interopDefault(import('../modules/wishlist/pages/Wishlist.vue' /* webpackChunkName: "" */))
const _d1444260 = () => interopDefault(import('../pages/Checkout/ExternalCheckoutThankYou.vue' /* webpackChunkName: "pages/Checkout/ExternalCheckoutThankYou" */))
const _3411cd32 = () => interopDefault(import('../modules/catalog/pages/product.vue' /* webpackChunkName: "" */))
const _2db708cb = () => interopDefault(import('../modules/catalog/pages/category.vue' /* webpackChunkName: "" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/a-propos",
    component: _67bab3ee,
    name: "about"
  }, {
    path: "/About",
    component: _67bab3ee,
    name: "About"
  }, {
    path: "/b2b_en",
    component: _543b868e,
    name: "home___b2b_en"
  }, {
    path: "/b2bFR",
    component: _543b868e,
    name: "home___b2bFR"
  }, {
    path: "/Cart",
    component: _719a044f,
    name: "Cart"
  }, {
    path: "/Cgu",
    component: _68c96d32,
    name: "Cgu"
  }, {
    path: "/Cgv",
    component: _68d784b3,
    name: "Cgv"
  }, {
    path: "/checkout",
    component: _04333d84,
    name: "checkout",
    children: [{
      path: "billing",
      component: _3917cc8a,
      name: "billing"
    }, {
      path: "payment",
      component: _5071c2d6,
      name: "payment"
    }, {
      path: "shipping",
      component: _299bfb2f,
      name: "shipping"
    }, {
      path: "thank-you",
      component: _1329c6d6,
      name: "thank-you"
    }]
  }, {
    path: "/collections",
    redirect: (to) => '/c',
    name: "Collections"
  }, {
    path: "/comment-choisir-sa-robe-de-mariee",
    component: _2f2b8766,
    name: "how-to-choose-your-wedding-dress"
  }, {
    path: "/compte-en-attente",
    component: _5f96be05,
    name: "pending-page"
  }, {
    path: "/conditions-generales-utilisateurs",
    component: _68c96d32,
    name: "cgu"
  }, {
    path: "/conditions-generales-vente",
    component: _68d784b3,
    name: "cgv"
  }, {
    path: "/connexion",
    component: _511a31ca,
    name: "login"
  }, {
    path: "/contact",
    component: _7b1eda3f,
    name: "contact-us"
  }, {
    path: "/ContactUs",
    component: _7b1eda3f,
    name: "ContactUs"
  }, {
    path: "/Cookie",
    component: _4269f05a,
    name: "Cookie"
  }, {
    path: "/customer",
    component: _50d04e63,
    meta: {"titleLabel":"My Account"},
    name: "customer",
    children: [{
      path: "addresses-details",
      component: _090b3cd1,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details"
    }, {
      path: "my-newsletter",
      component: _304265f3,
      meta: {"titleLabel":"My newsletter"},
      name: "customer-my-newsletter"
    }, {
      path: "my-profile",
      component: _ef2449e6,
      meta: {"titleLabel":"My profile"},
      name: "customer-my-profile"
    }, {
      path: "my-reviews",
      component: _6f4a1151,
      meta: {"titleLabel":"My reviews"},
      name: "customer-my-reviews"
    }, {
      path: "my-wishlist",
      component: _792d117f,
      meta: {"titleLabel":"My wishlist"},
      name: "customer-my-wishlist"
    }, {
      path: "order-history",
      component: _3d141411,
      meta: {"titleLabel":"Order history"},
      name: "customer-order-history"
    }, {
      path: "addresses-details/create",
      component: _d5dc9b46,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details-new"
    }, {
      path: "addresses-details/edit/:addressId",
      component: _1d29aa1d,
      meta: {"titleLabel":"Addresses details"},
      props: true,
      name: "customer-addresses-details-edit"
    }, {
      path: "order-history/:orderId",
      component: _1cb8da42,
      meta: {"titleLabel":"Order history"},
      props: true,
      name: "customer-single-order"
    }]
  }, {
    path: "/DeliveryInformations",
    component: _9ff90eec,
    name: "DeliveryInformations"
  }, {
    path: "/events",
    component: _78ae7c08,
    name: "events"
  }, {
    path: "/Events",
    component: _78ae7c08,
    name: "Events"
  }, {
    path: "/faq",
    component: _05f2a592,
    name: "faq"
  }, {
    path: "/Faq",
    component: _05f2a592,
    name: "Faq"
  }, {
    path: "/FindOrder",
    component: _4a248cf6,
    name: "FindOrder"
  }, {
    path: "/Home",
    component: _543b868e,
    name: "Home"
  }, {
    path: "/HowToChooseWeddingDress",
    component: _2f2b8766,
    name: "HowToChooseWeddingDress"
  }, {
    path: "/informations-de-livraison",
    component: _9ff90eec,
    name: "delivery"
  }, {
    path: "/inscription",
    component: _94cdca5c,
    name: "register"
  }, {
    path: "/LegalNotice",
    component: _7f8f4adc,
    name: "LegalNotice"
  }, {
    path: "/Login",
    component: _511a31ca,
    name: "Login"
  }, {
    path: "/lookbook",
    component: _456d4bb7,
    name: "lookbook"
  }, {
    path: "/Lookbook",
    component: _456d4bb7,
    name: "Lookbook"
  }, {
    path: "/magasins",
    component: _87e987b8,
    name: "store-locator"
  }, {
    path: "/Page",
    component: _b71ec784,
    name: "Page"
  }, {
    path: "/panier",
    component: _719a044f,
    name: "cart"
  }, {
    path: "/PendingAccount",
    component: _5f96be05,
    name: "PendingAccount"
  }, {
    path: "/privacypolicy",
    component: _a6ac908a,
    name: "legal-notice"
  }, {
    path: "/privacypolicy",
    component: _a6ac908a,
    name: "privacy"
  }, {
    path: "/PrivacyPolicy",
    component: _a6ac908a,
    name: "PrivacyPolicy"
  }, {
    path: "/Register",
    component: _94cdca5c,
    name: "Register"
  }, {
    path: "/reset-password",
    component: _0c25adeb,
    alias: "/customer/account/createPassword",
    name: "reset-password"
  }, {
    path: "/ResetPassword",
    component: _0c25adeb,
    name: "ResetPassword"
  }, {
    path: "/retrouver-ma-commande",
    component: _4a248cf6,
    name: "find-order"
  }, {
    path: "/robe-de-mariee",
    component: _60aec9f2,
    name: "wedding-dress"
  }, {
    path: "/sizeguide",
    component: _1d3e3f48,
    name: "size-guide"
  }, {
    path: "/SizeGuide",
    component: _1d3e3f48,
    name: "SizeGuide"
  }, {
    path: "/StoreLocator",
    component: _87e987b8,
    name: "StoreLocator"
  }, {
    path: "/WeddingDress",
    component: _60aec9f2,
    name: "WeddingDress"
  }, {
    path: "/wishlist",
    component: _41d0635e,
    name: "wishlist"
  }, {
    path: "/b2b_en/a-propos",
    component: _67bab3ee,
    name: "about___b2b_en"
  }, {
    path: "/b2b_en/About",
    component: _67bab3ee,
    name: "About___b2b_en"
  }, {
    path: "/b2b_en/Cart",
    component: _719a044f,
    name: "Cart___b2b_en"
  }, {
    path: "/b2b_en/Cgu",
    component: _68c96d32,
    name: "Cgu___b2b_en"
  }, {
    path: "/b2b_en/Cgv",
    component: _68d784b3,
    name: "Cgv___b2b_en"
  }, {
    path: "/b2b_en/checkout",
    component: _04333d84,
    name: "checkout___b2b_en",
    children: [{
      path: "billing",
      component: _3917cc8a,
      name: "billing___b2b_en"
    }, {
      path: "payment",
      component: _5071c2d6,
      name: "payment___b2b_en"
    }, {
      path: "shipping",
      component: _299bfb2f,
      name: "shipping___b2b_en"
    }, {
      path: "thank-you",
      component: _1329c6d6,
      name: "thank-you___b2b_en"
    }]
  }, {
    path: "/b2b_en/comment-choisir-sa-robe-de-mariee",
    component: _2f2b8766,
    name: "how-to-choose-your-wedding-dress___b2b_en"
  }, {
    path: "/b2b_en/compte-en-attente",
    component: _5f96be05,
    name: "pending-page___b2b_en"
  }, {
    path: "/b2b_en/conditions-generales-utilisateurs",
    component: _68c96d32,
    name: "cgu___b2b_en"
  }, {
    path: "/b2b_en/conditions-generales-vente",
    component: _68d784b3,
    name: "cgv___b2b_en"
  }, {
    path: "/b2b_en/connexion",
    component: _511a31ca,
    name: "login___b2b_en"
  }, {
    path: "/b2b_en/contact",
    component: _7b1eda3f,
    name: "contact-us___b2b_en"
  }, {
    path: "/b2b_en/ContactUs",
    component: _7b1eda3f,
    name: "ContactUs___b2b_en"
  }, {
    path: "/b2b_en/Cookie",
    component: _4269f05a,
    name: "Cookie___b2b_en"
  }, {
    path: "/b2b_en/customer",
    component: _50d04e63,
    meta: {"titleLabel":"My Account"},
    name: "customer___b2b_en",
    children: [{
      path: "addresses-details",
      component: _090b3cd1,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details___b2b_en"
    }, {
      path: "my-newsletter",
      component: _304265f3,
      meta: {"titleLabel":"My newsletter"},
      name: "customer-my-newsletter___b2b_en"
    }, {
      path: "my-profile",
      component: _ef2449e6,
      meta: {"titleLabel":"My profile"},
      name: "customer-my-profile___b2b_en"
    }, {
      path: "my-reviews",
      component: _6f4a1151,
      meta: {"titleLabel":"My reviews"},
      name: "customer-my-reviews___b2b_en"
    }, {
      path: "my-wishlist",
      component: _792d117f,
      meta: {"titleLabel":"My wishlist"},
      name: "customer-my-wishlist___b2b_en"
    }, {
      path: "order-history",
      component: _3d141411,
      meta: {"titleLabel":"Order history"},
      name: "customer-order-history___b2b_en"
    }, {
      path: "addresses-details/create",
      component: _d5dc9b46,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details-new___b2b_en"
    }, {
      path: "addresses-details/edit/:addressId",
      component: _1d29aa1d,
      meta: {"titleLabel":"Addresses details"},
      props: true,
      name: "customer-addresses-details-edit___b2b_en"
    }, {
      path: "order-history/:orderId",
      component: _1cb8da42,
      meta: {"titleLabel":"Order history"},
      props: true,
      name: "customer-single-order___b2b_en"
    }]
  }, {
    path: "/b2b_en/DeliveryInformations",
    component: _9ff90eec,
    name: "DeliveryInformations___b2b_en"
  }, {
    path: "/b2b_en/events",
    component: _78ae7c08,
    name: "events___b2b_en"
  }, {
    path: "/b2b_en/Events",
    component: _78ae7c08,
    name: "Events___b2b_en"
  }, {
    path: "/b2b_en/faq",
    component: _05f2a592,
    name: "faq___b2b_en"
  }, {
    path: "/b2b_en/Faq",
    component: _05f2a592,
    name: "Faq___b2b_en"
  }, {
    path: "/b2b_en/FindOrder",
    component: _4a248cf6,
    name: "FindOrder___b2b_en"
  }, {
    path: "/b2b_en/Home",
    component: _543b868e,
    name: "Home___b2b_en"
  }, {
    path: "/b2b_en/HowToChooseWeddingDress",
    component: _2f2b8766,
    name: "HowToChooseWeddingDress___b2b_en"
  }, {
    path: "/b2b_en/informations-de-livraison",
    component: _9ff90eec,
    name: "delivery___b2b_en"
  }, {
    path: "/b2b_en/inscription",
    component: _94cdca5c,
    name: "register___b2b_en"
  }, {
    path: "/b2b_en/LegalNotice",
    component: _7f8f4adc,
    name: "LegalNotice___b2b_en"
  }, {
    path: "/b2b_en/Login",
    component: _511a31ca,
    name: "Login___b2b_en"
  }, {
    path: "/b2b_en/lookbook",
    component: _456d4bb7,
    name: "lookbook___b2b_en"
  }, {
    path: "/b2b_en/Lookbook",
    component: _456d4bb7,
    name: "Lookbook___b2b_en"
  }, {
    path: "/b2b_en/magasins",
    component: _87e987b8,
    name: "store-locator___b2b_en"
  }, {
    path: "/b2b_en/Page",
    component: _b71ec784,
    name: "Page___b2b_en"
  }, {
    path: "/b2b_en/panier",
    component: _719a044f,
    name: "cart___b2b_en"
  }, {
    path: "/b2b_en/PendingAccount",
    component: _5f96be05,
    name: "PendingAccount___b2b_en"
  }, {
    path: "/b2b_en/privacypolicy",
    component: _a6ac908a,
    name: "legal-notice___b2b_en"
  }, {
    path: "/b2b_en/privacypolicy",
    component: _a6ac908a,
    name: "privacy___b2b_en"
  }, {
    path: "/b2b_en/PrivacyPolicy",
    component: _a6ac908a,
    name: "PrivacyPolicy___b2b_en"
  }, {
    path: "/b2b_en/Register",
    component: _94cdca5c,
    name: "Register___b2b_en"
  }, {
    path: "/b2b_en/reset-password",
    component: _0c25adeb,
    alias: "/customer/account/createPassword",
    name: "reset-password___b2b_en"
  }, {
    path: "/b2b_en/ResetPassword",
    component: _0c25adeb,
    name: "ResetPassword___b2b_en"
  }, {
    path: "/b2b_en/retrouver-ma-commande",
    component: _4a248cf6,
    name: "find-order___b2b_en"
  }, {
    path: "/b2b_en/robe-de-mariee",
    component: _60aec9f2,
    name: "wedding-dress___b2b_en"
  }, {
    path: "/b2b_en/sizeguide",
    component: _1d3e3f48,
    name: "size-guide___b2b_en"
  }, {
    path: "/b2b_en/SizeGuide",
    component: _1d3e3f48,
    name: "SizeGuide___b2b_en"
  }, {
    path: "/b2b_en/StoreLocator",
    component: _87e987b8,
    name: "StoreLocator___b2b_en"
  }, {
    path: "/b2b_en/WeddingDress",
    component: _60aec9f2,
    name: "WeddingDress___b2b_en"
  }, {
    path: "/b2b_en/wishlist",
    component: _41d0635e,
    name: "wishlist___b2b_en"
  }, {
    path: "/b2bFR/a-propos",
    component: _67bab3ee,
    name: "about___b2bFR"
  }, {
    path: "/b2bFR/About",
    component: _67bab3ee,
    name: "About___b2bFR"
  }, {
    path: "/b2bFR/Cart",
    component: _719a044f,
    name: "Cart___b2bFR"
  }, {
    path: "/b2bFR/Cgu",
    component: _68c96d32,
    name: "Cgu___b2bFR"
  }, {
    path: "/b2bFR/Cgv",
    component: _68d784b3,
    name: "Cgv___b2bFR"
  }, {
    path: "/b2bFR/checkout",
    component: _04333d84,
    name: "checkout___b2bFR",
    children: [{
      path: "billing",
      component: _3917cc8a,
      name: "billing___b2bFR"
    }, {
      path: "payment",
      component: _5071c2d6,
      name: "payment___b2bFR"
    }, {
      path: "shipping",
      component: _299bfb2f,
      name: "shipping___b2bFR"
    }, {
      path: "thank-you",
      component: _1329c6d6,
      name: "thank-you___b2bFR"
    }]
  }, {
    path: "/b2bFR/comment-choisir-sa-robe-de-mariee",
    component: _2f2b8766,
    name: "how-to-choose-your-wedding-dress___b2bFR"
  }, {
    path: "/b2bFR/compte-en-attente",
    component: _5f96be05,
    name: "pending-page___b2bFR"
  }, {
    path: "/b2bFR/conditions-generales-utilisateurs",
    component: _68c96d32,
    name: "cgu___b2bFR"
  }, {
    path: "/b2bFR/conditions-generales-vente",
    component: _68d784b3,
    name: "cgv___b2bFR"
  }, {
    path: "/b2bFR/connexion",
    component: _511a31ca,
    name: "login___b2bFR"
  }, {
    path: "/b2bFR/contact",
    component: _7b1eda3f,
    name: "contact-us___b2bFR"
  }, {
    path: "/b2bFR/ContactUs",
    component: _7b1eda3f,
    name: "ContactUs___b2bFR"
  }, {
    path: "/b2bFR/Cookie",
    component: _4269f05a,
    name: "Cookie___b2bFR"
  }, {
    path: "/b2bFR/customer",
    component: _50d04e63,
    meta: {"titleLabel":"My Account"},
    name: "customer___b2bFR",
    children: [{
      path: "addresses-details",
      component: _090b3cd1,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details___b2bFR"
    }, {
      path: "my-newsletter",
      component: _304265f3,
      meta: {"titleLabel":"My newsletter"},
      name: "customer-my-newsletter___b2bFR"
    }, {
      path: "my-profile",
      component: _ef2449e6,
      meta: {"titleLabel":"My profile"},
      name: "customer-my-profile___b2bFR"
    }, {
      path: "my-reviews",
      component: _6f4a1151,
      meta: {"titleLabel":"My reviews"},
      name: "customer-my-reviews___b2bFR"
    }, {
      path: "my-wishlist",
      component: _792d117f,
      meta: {"titleLabel":"My wishlist"},
      name: "customer-my-wishlist___b2bFR"
    }, {
      path: "order-history",
      component: _3d141411,
      meta: {"titleLabel":"Order history"},
      name: "customer-order-history___b2bFR"
    }, {
      path: "addresses-details/create",
      component: _d5dc9b46,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details-new___b2bFR"
    }, {
      path: "addresses-details/edit/:addressId",
      component: _1d29aa1d,
      meta: {"titleLabel":"Addresses details"},
      props: true,
      name: "customer-addresses-details-edit___b2bFR"
    }, {
      path: "order-history/:orderId",
      component: _1cb8da42,
      meta: {"titleLabel":"Order history"},
      props: true,
      name: "customer-single-order___b2bFR"
    }]
  }, {
    path: "/b2bFR/DeliveryInformations",
    component: _9ff90eec,
    name: "DeliveryInformations___b2bFR"
  }, {
    path: "/b2bFR/events",
    component: _78ae7c08,
    name: "events___b2bFR"
  }, {
    path: "/b2bFR/Events",
    component: _78ae7c08,
    name: "Events___b2bFR"
  }, {
    path: "/b2bFR/faq",
    component: _05f2a592,
    name: "faq___b2bFR"
  }, {
    path: "/b2bFR/Faq",
    component: _05f2a592,
    name: "Faq___b2bFR"
  }, {
    path: "/b2bFR/FindOrder",
    component: _4a248cf6,
    name: "FindOrder___b2bFR"
  }, {
    path: "/b2bFR/Home",
    component: _543b868e,
    name: "Home___b2bFR"
  }, {
    path: "/b2bFR/HowToChooseWeddingDress",
    component: _2f2b8766,
    name: "HowToChooseWeddingDress___b2bFR"
  }, {
    path: "/b2bFR/informations-de-livraison",
    component: _9ff90eec,
    name: "delivery___b2bFR"
  }, {
    path: "/b2bFR/inscription",
    component: _94cdca5c,
    name: "register___b2bFR"
  }, {
    path: "/b2bFR/LegalNotice",
    component: _7f8f4adc,
    name: "LegalNotice___b2bFR"
  }, {
    path: "/b2bFR/Login",
    component: _511a31ca,
    name: "Login___b2bFR"
  }, {
    path: "/b2bFR/lookbook",
    component: _456d4bb7,
    name: "lookbook___b2bFR"
  }, {
    path: "/b2bFR/Lookbook",
    component: _456d4bb7,
    name: "Lookbook___b2bFR"
  }, {
    path: "/b2bFR/magasins",
    component: _87e987b8,
    name: "store-locator___b2bFR"
  }, {
    path: "/b2bFR/Page",
    component: _b71ec784,
    name: "Page___b2bFR"
  }, {
    path: "/b2bFR/panier",
    component: _719a044f,
    name: "cart___b2bFR"
  }, {
    path: "/b2bFR/PendingAccount",
    component: _5f96be05,
    name: "PendingAccount___b2bFR"
  }, {
    path: "/b2bFR/privacypolicy",
    component: _a6ac908a,
    name: "legal-notice___b2bFR"
  }, {
    path: "/b2bFR/privacypolicy",
    component: _a6ac908a,
    name: "privacy___b2bFR"
  }, {
    path: "/b2bFR/PrivacyPolicy",
    component: _a6ac908a,
    name: "PrivacyPolicy___b2bFR"
  }, {
    path: "/b2bFR/Register",
    component: _94cdca5c,
    name: "Register___b2bFR"
  }, {
    path: "/b2bFR/reset-password",
    component: _0c25adeb,
    alias: "/customer/account/createPassword",
    name: "reset-password___b2bFR"
  }, {
    path: "/b2bFR/ResetPassword",
    component: _0c25adeb,
    name: "ResetPassword___b2bFR"
  }, {
    path: "/b2bFR/retrouver-ma-commande",
    component: _4a248cf6,
    name: "find-order___b2bFR"
  }, {
    path: "/b2bFR/robe-de-mariee",
    component: _60aec9f2,
    name: "wedding-dress___b2bFR"
  }, {
    path: "/b2bFR/sizeguide",
    component: _1d3e3f48,
    name: "size-guide___b2bFR"
  }, {
    path: "/b2bFR/SizeGuide",
    component: _1d3e3f48,
    name: "SizeGuide___b2bFR"
  }, {
    path: "/b2bFR/StoreLocator",
    component: _87e987b8,
    name: "StoreLocator___b2bFR"
  }, {
    path: "/b2bFR/WeddingDress",
    component: _60aec9f2,
    name: "WeddingDress___b2bFR"
  }, {
    path: "/b2bFR/wishlist",
    component: _41d0635e,
    name: "wishlist___b2bFR"
  }, {
    path: "/Checkout/ExternalCheckoutThankYou",
    component: _d1444260,
    name: "Checkout-ExternalCheckoutThankYou"
  }, {
    path: "/b2b_en/Checkout/ExternalCheckoutThankYou",
    component: _d1444260,
    name: "Checkout-ExternalCheckoutThankYou___b2b_en"
  }, {
    path: "/b2bFR/Checkout/ExternalCheckoutThankYou",
    component: _d1444260,
    name: "Checkout-ExternalCheckoutThankYou___b2bFR"
  }, {
    path: "/b2b_en/p/:id/:slug",
    component: _3411cd32,
    name: "product___b2b_en"
  }, {
    path: "/b2bFR/p/:id/:slug",
    component: _3411cd32,
    name: "product___b2bFR"
  }, {
    path: "/b2b_en/c/:slug_1/:slug_2?/:slug_3?/:slug_4?/:slug_5?",
    component: _2db708cb,
    name: "category___b2b_en"
  }, {
    path: "/b2bFR/c/:slug_1/:slug_2?/:slug_3?/:slug_4?/:slug_5?",
    component: _2db708cb,
    name: "category___b2bFR"
  }, {
    path: "/b2b_en/:slug+",
    component: _b71ec784,
    name: "page___b2b_en"
  }, {
    path: "/b2bFR/:slug+",
    component: _b71ec784,
    name: "page___b2bFR"
  }, {
    path: "/p/:id/:slug/",
    component: _3411cd32,
    name: "product"
  }, {
    path: "/c/:slug_1/:slug_2?/:slug_3?/:slug_4?/:slug_5?",
    component: _2db708cb,
    name: "category"
  }, {
    path: "/",
    component: _543b868e,
    name: "home"
  }, {
    path: "/:slug+",
    component: _b71ec784,
    name: "page"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
