
































import { defineComponent, onMounted } from '@nuxtjs/composition-api';
import { SfButton, SfImage } from '@storefront-ui/vue';

export default defineComponent({
  name: 'error',
  components: {
    SfButton,
    SfImage,
  },
  layout: 'defaultWithoutFooter',
  props: {
    error: {
      type: Object,
      required: true,
    },
  },
});
