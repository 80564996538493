

































































































import {
  defineComponent,
  useContext,
  ref,
  onMounted, useRoute
} from '@nuxtjs/composition-api';
import { SfTopBar, SfLink, SfIcon } from '@storefront-ui/vue';
import { useTopBar } from './useTopBar';
import { useUser } from '~/modules/customer/composables/useUser';
import { useHidePriceStore } from '~/components/TopBar/hidePriceStore';

export default defineComponent({
  components: {
    StoreSwitcher: () => import('../StoreSwitcher.vue'),
    SfTopBar,
    SfLink,
    SfIcon
  },
  setup() {
    const { app } = useContext();
    const { isAuthenticated } = useUser();
    const { hasCurrencyToSelect, hasStoresToSelect } = useTopBar();
    const hidePriceStore = useHidePriceStore();
    const isVisible = ref(hidePriceStore.hidePrice);
    const { load: loadUser } = useUser();
    const route = useRoute();

    const linkB2C = route.value.path.substring(0, 6) != '/b2bFR' ? process.env.PROMULIAS_URL + 'en/' : process.env.PROMULIAS_URL;

    const user = ref(null);
    onMounted(async () => {
      user.value = await loadUser();
      if (localStorage.getItem('isVisible') === 'false') {
        hidePriceStore.hidePrice = false;
      } else {
        localStorage.setItem('isVisible', true);
        hidePriceStore.hidePrice = true;
      }
      isVisible.value = hidePriceStore.hidePrice;
    });

    function hidePrice() {
      if (localStorage.getItem('isVisible') !== null) {
        hidePriceStore.hidePrice = !hidePriceStore.hidePrice;
        isVisible.value = hidePriceStore.hidePrice;
        localStorage.setItem('isVisible', hidePriceStore.hidePrice);
      } else {
        hidePriceStore.hidePrice = true;
        isVisible.value = true;
        localStorage.setItem('isVisible', hidePriceStore.hidePrice);
      }
    }

    return {
      hasCurrencyToSelect,
      hasStoresToSelect,
      app,
      hidePrice,
      isVisible,
      hidePriceStore,
      isAuthenticated,
      user,
      linkB2C
    };
  }
});
