








import { useRoute, defineComponent } from '@nuxtjs/composition-api';
import TopBar from '~/components/TopBar/TopBar.vue';

export default defineComponent({
  name: 'LoginLayout',
  components: {
    TopBar,
  },
  setup() {
    const route = useRoute();
    return {
      route,
    };
  },
});
