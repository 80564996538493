




































import LazyHydrate from 'vue-lazy-hydration';

import { SfButton, SfCharacteristic, SfImage } from '@storefront-ui/vue';

import { ref, defineComponent, useRoute } from '@nuxtjs/composition-api';
import StoresModal from '~/components/StoreSwitcher/StoresModal.vue';
import { useConfig } from '~/composables';

export default defineComponent({
  name: 'StoreSwitcher',
  components: {
    StoresModal,
    SfButton,
    SfCharacteristic,
    SfImage,
    LazyHydrate,
  },
  setup() {
    const { config } = useConfig();
    const route = useRoute();
    const isLangModalOpen = ref(false);
    if(route.value.path.substring(0,7)=== "/b2b_en"){
      config.value.locale ="en_GB"
      config.value.header_logo_src="websites/1/Promulias-Logo-Noir.png"
      config.value.store_code = "en"
      config.value.store_name = "English_BToC"
    }else {
      config.value.locale ="fr_FR"
      config.value.header_logo_src="stores/1/Promulias-Logo-Noir.png"
      config.value.store_code = "fr"
      config.value.store_name = "Français_BToC"
    }
    return {
      isLangModalOpen,
      storeConfig: config,
    };
  },
});
